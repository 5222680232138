
import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';

export default Vue.extend({
  name: 'RoleInfo',
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    enable: {
      type: Boolean,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    saving(val) {
      if (!val) return;
      (this.$refs.roleForm as ElForm).validate((valid) => {
        this.$emit('pass', valid);
      });
    },
    name(val) {
      this.form.name = val;
    },
  },
});
