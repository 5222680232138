
  import Vue from 'vue';
  import * as api from '@/api';
  import RoleInfo from './RoleInfo.vue';
  import RolePermission from './RolePermission.vue';

  export default Vue.extend({
    name: 'RoleCreate',
    components: { RoleInfo, RolePermission },
    data() {
      return {
        name: '',
        description: '',
        enable: true,
        permissionTree: [] as api.RolePermissionTreeNode[],
        saving: false,
      };
    },
    computed: {
      permissionFlatted(): { [id: string]: api.RolePermissionTreeNode } {
        const ret = {};
        const treeFlat = (tree: api.RolePermissionTreeNode) => {
          ret[tree.id] = tree;
          tree.children.forEach((c) => treeFlat(c));
        };
        this.permissionTree.forEach((node) => treeFlat(node));
        return ret;
      },
      editMode(): boolean {
        return !!this.$store.state.editRole;
      },
      editRole(): api.Role {
        return this.$store.state.editRole;
      },
    },
    async created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '角色权限',
          path: 'role',
        },
        {
          name: !this.editRole ? '创建角色' : '编辑角色',
          path: !this.editRole ? 'role-create' : 'role-edit',
        },
      ]);
      const res = this.editMode ? await api.getRolePermissionTree({ id: this.editRole.id }) : await api.getPermissionTree();
      if (!api.error(res)) {
        this.permissionTree = res.data.roots;
      }
      if (this.editMode) {
        this.name = this.editRole.name;
        this.enable = this.editRole.enabled;
        this.description = this.editRole.description;
      }
    },
    methods: {
      cancel() {
        this.$router.back();
      },
      pass(val) {
        if (val) {
          this.submit();
        } else {
          this.saving = false;
        }
      },
      async submit() {
        const option = {
          name: this.name,
          description: this.description,
          enabled: this.enable,
          selectedPermissionIds: Object.keys(this.permissionFlatted).filter((id) => this.permissionFlatted[id].selected),
        };
        const res = this.editMode ? await api.editRole({ ...option, id: this.editRole.id }) : await api.createRole(option);
        if (!api.error(res)) {
          this.$message({
            type: 'success',
            message: '保存成功',
          });
          this.$router.back();
        } else {
          this.$message({
            type: 'error',
            message: res.error.message,
          });
        }
        this.saving = false;
      },
    },
  });
