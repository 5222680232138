
import Vue, { PropType } from 'vue';
import * as api from '@/api';

/**
 * methods: reset
 */
export default Vue.extend({
  name: 'RolePermission',
  props: {
    tree: {
      type: Array as PropType<api.RolePermissionTreeNode[]>,
      required: true,
    },
    flatted: {
      type: Object as PropType<{ [id: string]: api.RolePermissionTreeNode }>,
      required: true,
    },
  },
  data() {
    return {
      initFlatted: {} as { [id: string]: boolean },
    };
  },
  created() {
    Object.keys(this.flatted).forEach((id) => {
      this.initFlatted[id] = this.flatted[id].selected;
    });
  },
  methods: {
    select(id, flag) {
      this.flatted[id].selected = flag;
      if (!flag) {
        this.flatted[id].children.map((c) => c.id).forEach((cid) => this.select(cid, flag));
      }
      this.$emit('update:hasEdited', true);
    },
    reset() {
      Object.keys(this.initFlatted).forEach((id) => {
        this.flatted[id].selected = this.initFlatted[id];
      });
      this.$emit('update:hasEdited', false);
    },
  },
});
